<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="当前状态">
        <el-select
          @change="handleDepot()"
          v-model="searchForm.status"
          placeholder="请选择查询条件"
        >
          <el-option label="正常" :value="1"></el-option>
          <el-option label="回收站" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="handleQuery()">查询</el-button>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="add">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
          v-if="item.prop == 'banner_url'"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <img
              :src="scope.row.banner_url"
              style="width: 200px; height: 100px"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button @click="handleEdit(scope.row)" style="margin-left: 10px"
            >编辑</el-button
          >
          <el-button
            style="margin-left: 10px"
            @click.native.stop="handleDelete(scope.row._id, scope.row.status)"
            >{{ scope.row.status == 1 ? "删除" : "恢复" }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination
        @current-change="handleQuery"
        :current-page.sync="pager.pageNum"
        layout="prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="bannerData.view_type == 'add' ? '新增Banner' : '修改Banner'"
      :visible.sync="bannerVisible"
      v-if="bannerVisible"
      :close-on-click-modal="false"
      v-loading="loading"
      width="500px"
    >
      <el-form ref="bannerForm" :model="bannerData" label-width="100px">
        <el-form-item
          label="排序"
          prop="sort"
          :rules="[
            {
              required: true,
              message: '请输入排序',
              trigger: ['blur', 'change'],
            },
            {
              validator: validatorInteger,
              message: '请输入大于0的整数',
              trigger: ['change', 'blur'],
            },
          ]"
        >
          <el-input
            type="number"
            v-model="bannerData.sort"
            placeholder="请输入排序"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="图片"
          prop="banner_url"
          :rules="[
            {
              required: true,
              message: '请上传图片',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :before-upload="beforeImageUpload"
            action="#"
            :http-request="httpUploadRequest"
          >
            <img
              v-if="bannerData.banner_url"
              :src="bannerData.banner_url"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="saveBanner">确定</el-button>
          <el-button @click="bannerVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { randomStr } from "@/utils/randomStr";
import { uploadOssUrl } from "@/utils/uploadUrl";
import { MessageBox } from "element-ui";

export default {
  name: "s1_banners",
  computed: {
    ...mapState("match", ["match_types"]),
  },
  data() {
    return {
      pager: {
        total: 10,
        pageNum: 1,
      },
      ids: [],
      type_id: "s1",
      match_id: "",
      loading: false,
      searchForm: {
        status: 1,
      },
      dataTable: [],
      dataList: [
        {
          prop: "sort",
          label: "排序",
          width: 300,
        },
        {
          prop: "banner_url",
          label: "Banner",
          width: 300,
        },
        {
          prop: "status",
          label: "当前状态",
          width: 200,
          formatter: (row) => {
            switch (row.status) {
              case 1:
                return "正常";
              case 0:
                return "回收站";
              default:
                return "";
            }
          },
        },
      ],
      bannerVisible: false,
      bannerData: {
        view_type: "add",
        _id: "",
        sort: "",
        banner_url: "",
      },
      match: false,
    };
  },
  methods: {
    beforeImageUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }

      return isJPG && isLt2M;
    },
    async httpUploadRequest(item) {
      this.loading = true;
      let fileName = this.match_id + "_" + randomStr() + ".jpg";
      let path = uploadOssUrl().banners + fileName;
      console.log("tempPath", path);
      let result = await this.$http.uploadFile(path, fileName, item.file);
      if (result) {
        this.bannerData.banner_url = result;
      } else {
        this.$message.error("上传失败");
      }
      this.loading = false;
    },
    //规则校验
    validatorInteger(rule, value, callback) {
      if (!value.length || !value.trim().length) {
        callback();
        return;
      }

      if (isNaN(Number.parseInt(value))) {
        callback(new Error("请输入大于0的整数"));
      }

      if (Number.isInteger(Number(value)) && Number(value) > 0) {
        callback();
      } else {
        callback(new Error("请输入大于0的整数"));
      }
    },
    // 添加初始化
    add() {
      this.bannerData.view_type = "add";
      this.bannerData._id = "";
      this.bannerData.sort = "";
      this.bannerData.banner_url = "";
      this.bannerVisible = true;
    },
    //编辑赋值
    handleEdit(row) {
      this.bannerData.view_type = "edit";
      this.bannerData._id = row._id;
      this.bannerData.sort = row.sort;
      this.bannerData.banner_url = row.banner_url;
      this.bannerVisible = true;
    },
    //添加banner
    saveBanner() {
      this.$refs.bannerForm.validate(async (valid) => {
        //校验
        if (valid) {
          this.loading = true;
          let saveData = {
            _id: this.bannerData._id,
            sort: Number.parseInt(this.bannerData.sort + ""),
            banner_url: this.bannerData.banner_url,
          };

          let { data, code } = await this.$http.operate.match_save_banner(
            saveData
          );
          this.loading = false;

          if (code == 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });

            this.bannerVisible = false;
            this.handleQuery();
          }
        }
      });
    },
    //删除
    async handleDelete(_id, status) {
      try {
        let status_title = status == 1 ? "是否删除" : "是否恢复";
        await MessageBox.confirm(status_title, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });

        let new_status = status == 0 ? 1 : 0;
        const { code } = await this.$http.operate.match_delete_banner({
          _id: _id,
          status: new_status,
        });
        if (code === 0) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.handleQuery();
        }
      } catch (error) {
        console.log(error);
      }
    },
    //查询按钮
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.handleQuery();
    },
    //重置回收站的初始页码
    handleDepot() {
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    //查询列表
    async handleQuery() {
      const { pageNum } = this.pager;
      this.loading = true;
      //请求接口
      const { data, errorCode } = await this.$http.operate.match_banners({
        pageNum,
        status: this.searchForm.status,
      });
      this.loading = false;
      this.dataTable = data.data;
      this.pager = data.pager;
      console.log("3232323232", this.pager);
    },
    //tab的变化
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async mounted() {
    //查询列表
    this.handleQuery();
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 100px;
}
</style>

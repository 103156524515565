//路径区分
export function uploadOssUrl() {
  console.log(process.env.NODE_ENV);
  let ossUrl = {
    // 公告管理 报名宣传图
    notice:
      process.env.NODE_ENV == "development"
        ? "lrs_community/match_test/images/"
        : "lrs_community/match/images/",
    // 赛事规则  赛事奖励
    richText:
      process.env.NODE_ENV == "development"
        ? "lrs_community/match_test/"
        : "lrs_community/match/",
    //队伍管理
    teams:
      process.env.NODE_ENV == "development"
        ? "lrs_community/match_test/images/teams/"
        : "lrs_community/match/images/teams/",
    //赛事日程banner  赛事banner
    banners:
      process.env.NODE_ENV == "development"
        ? "lrs_community/match_test/images/banners/"
        : "lrs_community/match/images/banners/",

    //英雄榜 门派联赛  门派名单  活动评审头像

    sect:
      process.env.NODE_ENV == "development"
        ? "lrs_community/armory_test/sect/images/"
        : "lrs_community/armory/sect/images/",
    // 称号
    title:
      process.env.NODE_ENV == "development"
        ? "lrs_community/armory_test/"
        : "lrs_community/armory/",
    //记者团
    reporter:
      process.env.NODE_ENV == "development"
        ? "lrs_community/armory_test/reporter/images/"
        : "lrs_community/armory/reporter/images/",
    //认证裁判
    referee:
      process.env.NODE_ENV == "development"
        ? "lrs_community/armory_test/referee/images/"
        : "lrs_community/armory/referee/images/",

    //活动
    activity:
      process.env.NODE_ENV == "development"
        ? "lrs_community/activity_test/"
        : "lrs_community/activity/",

    //活动规则
    activity_rule:
      process.env.NODE_ENV == "development"
        ? "lrs_community/activity_test/rule_desc/images/"
        : "lrs_community/activity/rule_desc/images/",

    //置顶图
    activity_img:
      process.env.NODE_ENV == "development"
        ? "lrs_community/activity_test/images/"
        : "lrs_community/activity/images/",
    //活动奖励
    activity_prize:
      process.env.NODE_ENV == "development"
        ? "lrs_community/activity_test/prize/images/"
        : "lrs_community/activity/prize/images/",
    static_assets:
      process.env.NODE_ENV == "development"
        ? "lrs_community/static_assets_test/"
        : "lrs_community/static_assets/",
    qr_img:
      process.env.NODE_ENV == "development"
        ? "lrs_community/match/s1/images/qrcode_test/"
        : "lrs_community/match/s1/images/qrcode/",
  };
  return ossUrl;
}

const uuid = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
}

const randomStr = function () {
    let guid = uuid();
    let regex = new RegExp('-', 'g')
    guid = guid.replace(regex, '');
    return guid;
}

export {
    randomStr,
    uuid
}
